import {User} from '@/types/user';

import {ERROR_LEVEL} from '@/constants';
import {FBUser} from '@/hooks/useFirebaseUser';
import {kaLog} from '@/services/tracking/kastaLogs/KaLog';

import type {KaLog} from '@/services/tracking/kastaLogs/KaLog';
import type {AnalyticsEvent} from '@/types/tracking';

import {events} from './event';
import {mixPanel} from './MixPanel';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

/**
 * Sometimes events logged inside of components gets logged twice due to re-rendering. To prevent that from happening
 * you can set an idempotencyId on your event, then this service will guarantee that the same event won't be logged again
 * on the same page.
 */
export default class AnalyticsService {
  private kastaLog: KaLog;
  private fbUser?: FBUser;
  private kastaUser?: User | null;
  public events = events;

  constructor(kastaLog: KaLog) {
    this.kastaLog = kastaLog;
  }

  setUser(fbUser: FBUser | null, kastaUser: User | null) {
    this.fbUser = fbUser;
    this.kastaUser = kastaUser;
    this.dataLayerPush({userId: this.getUserId()});
  }

  async logEvent({name, params, idempotencyId}: AnalyticsEvent) {
    if (this.idempotencyLockExists(idempotencyId)) {
      return;
    }
    const eventData = {
      event: name,
      userId: this.getUserId(),
      // Add additional info related to user kyc and cash account
      ...(this.kastaUser
        ? {
            cashAccountStatus: this.kastaUser.cashAccountStatus,
            kycLevel: this.kastaUser.kyc?.level,
            kycStatus: this.kastaUser.kyc?.status,
            userStatus: this.kastaUser.userStatus,
          }
        : {}),
      ...(params ? params : {}),
      idempotencyId,
    };
    await this.dataLayerPush(eventData);
    mixPanel?.track(name, params);
  }

  private idempotencyLockExists(id?: string) {
    if (!window.dataLayer || !id) {
      return false;
    }
    return !!window.dataLayer.find(i => i.idempotencyId === id);
  }

  private async dataLayerPush(object: any) {
    if (!window.dataLayer) {
      await this.analyticsNotInitialized();
      return;
    }

    try {
      window.dataLayer.push(object);
    } catch (e) {
      await this.analyticsLogError();
    }
  }

  private getUserId() {
    if (!this.fbUser || this.fbUser.isAnonymous) {
      return undefined;
    }
    return this.fbUser.uid;
  }

  private async analyticsNotInitialized() {
    try {
      await this.kastaLog.error({
        error_message: 'analytics not initialized',
        error_message_id: 'analytics_not_initialized',
        error_level: ERROR_LEVEL.NOTICE,
      });
    } catch (error) {
      console.log(`failed to backend logging analytics_not_initialized`, error);
    }
  }

  private async analyticsLogError() {
    try {
      await this.kastaLog.error({
        error_message: 'analytics log error',
        error_message_id: 'analytics_log_error',
        error_level: ERROR_LEVEL.NOTICE,
      });
    } catch (error) {
      console.log(`failed to backend logging analytics_log_error`, error);
    }
  }
}

export const analytics = new AnalyticsService(kaLog);
