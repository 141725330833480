import {createContext, useContext, useEffect, useMemo} from 'react';

import {Currency} from '@/graphql/__generated__/graphql';
import {accountUpdatedSubscription} from '@/graphql/subscriptions';

import {afterGet} from '@/controllers/account';
import {afterGet as afterGetSupportedAssets} from '@/controllers/supportedBlockchainAssets';
import {getSupportedBlockchainAssets} from '@/graphql/queries';
import {useQuery, useSubscription} from '@apollo/client';

import {useAppConfig} from '@/contexts/AppConfigContext';
import {useFormattedAmount} from '@/hooks/useFormattedAmount';
import {tracking} from '@/services/tracking/TrackingService';
import {useSupportedAssets} from '@/utils/assets';
import {isCashAccountActivated as isAccountActivated, isUserEligibleForCashAccount} from '@/utils/cash';
import {getConvertedBalances} from '@/utils/currencies';

import type {
  Balances,
  BlockchainAsset,
  CurrencyBalance,
  ExchangeRates,
  GetSupportedBlockchainAssetsQuery,
  GetSupportedBlockchainAssetsQueryVariables,
  PublishedUserAccountSubscription,
} from '@/graphql/__generated__/graphql';
import type {Asset, SupportedAssets} from '@/types/assets';
import type {ReactNode} from 'react';

import {useExchangeRates} from './ExchangeRatesContext';
import {useUser} from './UserContext';

const ASSET_PROPERTIES = {
  ADDRES_TYPE: 'addressType',
  AMOUNT: 'amount',
  AMOUNT_FORMATTED: 'amountFormatted',
  ASSET_ID: 'assetId',
  CONVERTED_AMOUNT: 'convertedAmount',
  CONVERTED_AMOUNT_FORMATTED: 'convertedAmountFormatted',
  CURRENCY: 'currency',
  DECIMAL_DIGITS: 'decimalDigits',
  EXPLORER: 'explorer',
  ID: 'id',
  IMAGE: 'image',
  MESSAGE: 'message',
  NAME: 'name',
  SYMBOL: 'symbol',
} as const;

type ASSET_PROPERTIES_TYPES = keyof typeof ASSET_PROPERTIES;
type CurrencyValues = `${Currency}`;

type SupportedBlockchainAssetsContextType = {
  supportedBlockchainAssets: BlockchainAsset[];
  loadingSupportedBlockchainAssets: boolean;
  refetchSupportedBlockchainAssets: Function;
};

type AccountContextType = {
  account: Balances | null;
  accountLoading: boolean;
  accountError: string;
  accountTotalFiatBalance: string | null;
  isAccountTotalFiatBalanceLoaded: boolean;
  accountFormattedCryptoAssetList: Asset[];
  accountFormattedCashAssetList: Asset[];
  accountFormattedAllAssetList: Asset[];
  accountFormattedAssetMap: Partial<{[key in Currency]: Asset}> | undefined;
  hasBalance: boolean;
  getAccountAssetBalanceProp: (currency: CurrencyValues, prop: ASSET_PROPERTIES_TYPES) => string | number;
  currencyBalances: CurrencyBalance[];
};

const AccountContext = createContext<AccountContextType & SupportedBlockchainAssetsContextType>({
  account: null,
  accountLoading: false,
  accountError: '',
  accountTotalFiatBalance: null,
  isAccountTotalFiatBalanceLoaded: false,
  accountFormattedCryptoAssetList: [],
  accountFormattedCashAssetList: [],
  accountFormattedAllAssetList: [],
  accountFormattedAssetMap: undefined,
  hasBalance: false,
  getAccountAssetBalanceProp: () => '',
  supportedBlockchainAssets: [],
  loadingSupportedBlockchainAssets: true,
  refetchSupportedBlockchainAssets: () => null,
  currencyBalances: [],
});

export const useAccount = () => useContext(AccountContext);

const AccountContextProvider = ({children}: {children: ReactNode}) => {
  const {user, displayCurrency, rawUserQuery, fetchAndSetUser, skipUserFetching} = useUser();
  const {exchangeRates} = useExchangeRates();
  const {appConfig} = useAppConfig();
  const {cashEnabledPwa, widgetOnRamp, widgetOffRamp} = appConfig;

  const isCashAvailable =
    cashEnabledPwa &&
    (isUserEligibleForCashAccount(user?.cashAccountStatus) || isAccountActivated(user?.cashAccountStatus));

  const {
    data: supportedAssetsData,
    refetch: refetchSupportedBlockchainAssets,
    loading: loadingSupportedBlockchainAssets,
  } = useQuery<GetSupportedBlockchainAssetsQuery, GetSupportedBlockchainAssetsQueryVariables>(
    getSupportedBlockchainAssets,
    {skip: !user},
  );

  const supportedBlockchainAssets = afterGetSupportedAssets(supportedAssetsData);

  const {
    data: subData,
    error: subError,
    loading: subLoading,
  } = useSubscription<PublishedUserAccountSubscription>(accountUpdatedSubscription, {skip: !user});

  useEffect(() => {
    if (subError && user?.id) {
      tracking.logEvent({
        name: 'SubscriptionError',
        params: {
          description: `Error in account subscription: ${subError.message}`,
          userId: user.id,
        },
      });
    }
  }, [subError, user]);

  useEffect(() => {
    if (skipUserFetching) {
      return;
    }
    if (!rawUserQuery?.getUser?.account) {
      //! this is a temporary fix for the issue of account not being fetched on the first render
      //! due to a cached version of getUser after subscription
      fetchAndSetUser();
    }
  }, [fetchAndSetUser, rawUserQuery, skipUserFetching]);

  const finalBalances = useMemo(() => afterGet(subData || rawUserQuery), [subData, rawUserQuery]);

  const currencyBalances: CurrencyBalance[] = useMemo(() => {
    if (!user?.account?.currencyBalances) return [];
  
    return (user.account.currencyBalances as Record<string, any>[])
      .filter((balance) => balance?.__typename === 'CurrencyBalance')
      .map((balance) => ({
        currency: balance.currency || '',
        available: balance?.available?.toString() || '0',
        incoming: balance?.incoming?.toString() || '0',
        reserved: balance?.reserved?.toString() || '0',
        lockedUp: balance?.lockedUp?.toString() || '0',
      }));
  }, [user]);

  const convertedBalance = getConvertedBalances({
    balances: finalBalances,
    exchangeRates: (exchangeRates as ExchangeRates) || null,
    to: displayCurrency || null,
  });

  const formattedTotalBalance = useFormattedAmount({
    currency: displayCurrency || null,
    value: convertedBalance,
    minimumIntegerDigits: 2,
    hideSeparator: true,
    availableAssets: supportedBlockchainAssets,
  });

  const {cashAssets, cryptoAssets}: SupportedAssets = useSupportedAssets({
    widgetOnRamp,
    widgetOffRamp,
    balances: finalBalances,
    displayCurrency: displayCurrency || null,
    exchangeRates: (exchangeRates as ExchangeRates) || null,
    availableAssets: supportedBlockchainAssets,
  });

  const accountFormattedAssetMap = useMemo(() => {
    if (finalBalances) {
      const assets = [...cashAssets, ...cryptoAssets].map(asset => asset.currency);
      return assets.reduce((assetMap, item) => {
        const currentAsset = [...(isCashAvailable ? cashAssets : []), ...cryptoAssets].find(
          ({currency}) => currency === item,
        );
        return {
          ...assetMap,
          ...(currentAsset && {[item]: currentAsset}),
        };
      }, {});
    }
    return undefined;
  }, [finalBalances, isCashAvailable, cashAssets, cryptoAssets]);

  const getAccountAssetBalanceProp = useMemo(
    () => (currency: CurrencyValues, prop: ASSET_PROPERTIES_TYPES) => {
      const asset = [...(isCashAvailable ? cashAssets : []), ...cryptoAssets].find(
        asset => asset.currency === currency,
      ) as Asset & {
        convertedAmount: number;
      };
      return asset?.[ASSET_PROPERTIES[prop]] ?? 0;
    },
    [cashAssets, cryptoAssets, isCashAvailable],
  );

  const isAccountTotalFiatBalanceLoaded =
    Boolean(exchangeRates) && Boolean(Object.keys(exchangeRates).length) && Boolean(formattedTotalBalance);

  const hasBalance = Number(convertedBalance) !== 0 && isAccountTotalFiatBalanceLoaded;

  const value = useMemo(
    () => ({
      // Account
      account: finalBalances,
      accountLoading: subLoading,
      accountError: subError?.message || '',
      accountTotalFiatBalance: formattedTotalBalance,
      isAccountTotalFiatBalanceLoaded,
      accountFormattedCryptoAssetList: cryptoAssets,
      accountFormattedCashAssetList: isCashAvailable ? cashAssets : [],
      accountFormattedAllAssetList: [...cryptoAssets, ...(isCashAvailable ? cashAssets : [])],
      accountFormattedAssetMap,
      getAccountAssetBalanceProp,
      hasBalance,
      currencyBalances,
      // SupportedBlockchainAssets
      supportedBlockchainAssets,
      loadingSupportedBlockchainAssets,
      refetchSupportedBlockchainAssets,
    }),
    [
      accountFormattedAssetMap,
      cashAssets,
      cryptoAssets,
      finalBalances,
      formattedTotalBalance,
      getAccountAssetBalanceProp,
      hasBalance,
      isAccountTotalFiatBalanceLoaded,
      isCashAvailable,
      loadingSupportedBlockchainAssets,
      refetchSupportedBlockchainAssets,
      subError,
      subLoading,
      supportedBlockchainAssets,
      currencyBalances,
    ],
  );

  return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>;
};

export default AccountContextProvider;
