/**
 * Some documentation about KYC to give you more context about this features
 * https://kasta.atlassian.net/wiki/spaces/KA/pages/620953635/Compliance
 */
import {createContext, useContext, useMemo} from 'react';

import {KycStatus} from '@/graphql/__generated__/graphql';

import {afterGetUserComplianceSettings} from '@/controllers/userComplianceSettings';
import {getUserComplianceSettings} from '@/graphql/queries';
import {ApolloQueryResult, useQuery} from '@apollo/client';

import {KycLevel} from '@/constants';
import {useAppConfig} from '@/contexts/AppConfigContext';
import {useUser} from '@/contexts/UserContext';
import {getCurrentKycFlow, getNextKycAvailableFlow} from '@/utils/compliance';

import type {
  GetUserComplianceSettingsQuery,
  GetUserComplianceSettingsQueryVariables,
} from '@/graphql/__generated__/graphql';
import type {ComplianceInfoMap, KycSettingsInfo} from '@/types/compliance';
import type {ReactNode} from 'react';

type ComplianceContextType = {
  complianceSettings: ComplianceInfoMap | null;
  currentKycInfo: KycSettingsInfo;
  nextKycInfo: KycSettingsInfo;
  canSkipKyc: boolean;
  refetchComplianceSettings:
    | (() => Promise<ApolloQueryResult<GetUserComplianceSettingsQuery>>)
    | (() => null);
};

const ComplianceContext = createContext<ComplianceContextType>({
  complianceSettings: null,
  currentKycInfo: undefined,
  nextKycInfo: undefined,
  canSkipKyc: true,
  refetchComplianceSettings: () => null,
});
export const useComplianceContext = () => useContext(ComplianceContext);

const ComplianceContextProvider = ({children}: {children: ReactNode}) => {
  const {user} = useUser();
  const {appConfig} = useAppConfig();
  const {data: userComplianceSettingsQuery, refetch} = useQuery<
    GetUserComplianceSettingsQuery,
    GetUserComplianceSettingsQueryVariables
  >(getUserComplianceSettings, {skip: !user});

  const complianceSettings = useMemo(
    () => afterGetUserComplianceSettings(userComplianceSettingsQuery),
    [userComplianceSettingsQuery],
  );
  const currentKycInfo = useMemo(
    () => (user && complianceSettings ? getCurrentKycFlow(complianceSettings, user) : undefined),
    [complianceSettings, user],
  );
  const nextKycInfo = useMemo(
    () => (user && complianceSettings ? getNextKycAvailableFlow(complianceSettings, user) : undefined),
    [complianceSettings, user],
  );

  // User can skip KYC if it has completed at least one KYC
  const canSkipKyc = useMemo(() => {
    if (!user || !user?.kyc) {
      return false;
    }

    // Allow testing user only to ROW users
    if (
      !appConfig?.kycAuthorizerRowRestrictionEnabled &&
      [KycLevel.ROW0, KycLevel.ROW1].includes(user?.kyc?.level as KycLevel)
    ) {
      return true;
    }

    /**
     * Force KYC for RED_RETRY submission
     */
    if (user?.kyc?.status === KycStatus.RedRetry || user?.kyc?.pending?.status === KycStatus.RedRetry) {
      return false;
    }

    if (user?.kyc?.history?.length) {
      /**
       * In case a user has a reject KYC history we should force KYC until the last level is approved
       * this means nextKycInfo null and user.kyc.level === GREEN
       */
      if (!nextKycInfo && user.kyc.status === KycStatus.Green) {
        return true;
      }
      for (const history of user.kyc.history) {
        if (history?.status === KycStatus.RedRetry || history?.rejectReason) {
          return false;
        }
      }
    }

    // Allow  OLD EEA, ROW Level 2 and EEA user with GREEN status
    if (
      [KycLevel.One, KycLevel.EEA0, KycLevel.ROW2].includes(user.kyc.level as KycLevel) &&
      user?.kyc?.status === KycStatus.Green
    ) {
      return true;
    }

    /**
     * If user has no KYC history but a GREEN KYC status
     * it means user did row-0 flow and they can skip KYC
     * Otherwise, we force user to do KYC
     */
    return false;
  }, [user, nextKycInfo, appConfig?.kycAuthorizerRowRestrictionEnabled]);

  return (
    <ComplianceContext.Provider
      value={{
        complianceSettings,
        currentKycInfo,
        nextKycInfo,
        canSkipKyc,
        refetchComplianceSettings: refetch,
      }}>
      {children}
    </ComplianceContext.Provider>
  );
};

export default ComplianceContextProvider;
