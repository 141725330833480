import getConfig from 'next/config';

import {
  DeviceType,
  LogAppEventMutation,
  LogAppEventMutationVariables,
  PlatformType,
} from '@/graphql/__generated__/graphql';
import {User} from '@/types/user';
import dayjs from 'dayjs';

import {logAppEvent} from '@/graphql/mutations';
import {ApolloClient} from '@apollo/client';

import {configService} from '@/services/configService';
import {GeoLocation} from '@/services/user/UserService';
import {getBrowserInUse, getBrowserVersion, getDeviceType, getOperativeSystem} from '@/utils/device';
import {getUserInfoStorage} from '@/utils/user-info';

import type {FBUser} from '@/hooks/useFirebaseUser';
import type {ERROR_EVENT_DETAILS_TYPE, ERROR_EVENT_INFO, INFO_TYPES} from '@/types/tracking';

const {publicRuntimeConfig} = getConfig();
const isDev = process.env.NODE_ENV === 'development';

type STATIC_BASE_KASTA_LOG = {
  appVersion: string;
  operatingSystem: string;
  platformType: PlatformType.Pwa;
  deviceBrowser: string;
  deviceTypeCategory: string;
  deviceType: DeviceType.Web;
  browserVersion: string;
  geo: GeoLocation | undefined;
  ip: string | undefined;
};

type BASE_KASTA_LOG = STATIC_BASE_KASTA_LOG & {
  userPseudoId: string;
  updatedAt: string;
  userId: string;
  screenName: string;
  gaSessionId: string;
};

type FULL_KASTA_LOG_ERROR_DETAILS = BASE_KASTA_LOG & ERROR_EVENT_DETAILS_TYPE;

export class KaLog {
  private client: ApolloClient<any> | null = null;
  private fbUserId: string | null = null;
  private kastaUser: User | null = null;
  private baseStaticKastaLog: STATIC_BASE_KASTA_LOG = this.buildBaseStaticKastaLog();
  private screenName: string | 'NA' = 'NA';

  constructor() {}

  setApolloClient(client: ApolloClient<any>) {
    this.client = client;
  }

  setFirebaseUserId(fbUser: FBUser | null) {
    this.fbUserId = fbUser?.uid || null;
  }

  setKastaUser(user: User) {
    this.kastaUser = user;
  }

  setScreenName(screenName: string) {
    this.screenName = screenName;
  }

  error(err: ERROR_EVENT_INFO | FULL_KASTA_LOG_ERROR_DETAILS, fullEventSent: boolean = false) {
    isDev && console.log(`Log ~ error ${dayjs().format('HH:mm')}: `, 'ERROR', JSON.stringify(err));

    let details;
    if (fullEventSent) {
      details = err;
    } else {
      details = {
        ...this.buildBaseKastaLog(),
        eventName: ('error_message_id' in err && err.error_message_id) || 'error_message',
        eventInfo: err,
      };
    }

    return this.writeKaLog({detailType: 'ERROR', details: JSON.stringify(details)});
  }

  info(detailType: INFO_TYPES, details: any, fullEventSent: boolean = false) {
    isDev && console.log(`Log ~ info ${dayjs().format('HH:mm')}: `, detailType, JSON.stringify(details));

    const userData = this.kastaUser
      ? {
          cashAccountStatus: this.kastaUser.cashAccountStatus,
          kycLevel: this.kastaUser.kyc?.level,
          kycStatus: this.kastaUser.kyc?.status,
          userStatus: this.kastaUser.userStatus,
        }
      : {};

    const detailsJSON = JSON.stringify(
      fullEventSent
        ? {...details, ...userData}
        : {
            ...this.buildBaseKastaLog(),
            ...userData,
            eventName: details.eventName as string,
            eventInfo: details,
          },
    );
    return this.writeKaLog({
      detailType,
      details: detailsJSON,
    });
  }

  private writeKaLog({detailType, details}: {detailType: INFO_TYPES; details: string}) {
    if (!this.fbUserId) {
      console.log('No fbUser, not sending ka backend log');
      return Promise.resolve();
    }
    if (!this.client) {
      console.log('No apollo client, not sending ka backend log');
      return Promise.resolve();
    }

    if (configService.isSandbox()) {
      return Promise.resolve();
    }

    return this.client.mutate<LogAppEventMutation, LogAppEventMutationVariables>({
      mutation: logAppEvent,
      variables: {
        input: {
          detailType,
          //@ts-ignore (SCHEMA ask for string[] but works with string and it is used as string in app)
          details,
        },
      },
    });
  }

  getGACookieClientId() {
    const cookieName = '_ga';
    return this.getCookieValue(cookieName);
  }

  private getGACookieSessionId() {
    const cookieName = '_ga_L3ZQMC7K7Q';
    return this.getCookieValue(cookieName);
  }

  private getCookieValue(cookieName: string) {
    if (document && document.cookie.indexOf(cookieName) > -1) {
      const trimmedCookie = document.cookie.trim();
      const cookiesArray = trimmedCookie.split(';');
      return (
        cookiesArray.map(cookie => cookie.trim().split('=')).find(([key]) => key === cookieName)?.[1] || ''
      );
    }
    return '';
  }

  private buildBaseStaticKastaLog(): STATIC_BASE_KASTA_LOG {
    return {
      appVersion: publicRuntimeConfig.version || 'NA',
      operatingSystem: getOperativeSystem(),
      platformType: PlatformType.Pwa,
      deviceBrowser: getBrowserInUse(),
      deviceTypeCategory: getDeviceType(),
      deviceType: DeviceType.Web,
      browserVersion: getBrowserVersion(),
      ...getUserInfoStorage(),
    };
  }

  private buildBaseKastaLog(): BASE_KASTA_LOG {
    return {
      updatedAt: new Date().toISOString(),
      userPseudoId: this.getGACookieClientId(),
      gaSessionId: this.getGACookieSessionId(),
      userId: this.fbUserId || '',
      screenName: this.screenName,
      ...this.baseStaticKastaLog,
    };
  }
}

export const kaLog = new KaLog();
