import dynamic from 'next/dynamic';
import {useRouter} from 'next/router';

import {useNavigationContext} from '@/contexts/NavigationContext';
import useIsTargetMedia from '@/hooks/useIsTargetMedia';

import PageSpinner from '@/components/layout/PageSpinner';

import s from './PageLayout.module.scss';
import { classNames } from 'primereact/utils';

const DynamicDesktopNavBar = dynamic(() => import('../layout/navbar/DesktopNavBar/DesktopNavBar'));
const DynamicMobileNavBar = dynamic(() => import('../layout/navbar/MobileNavBar/MobileNavBar'));

interface PageLayoutProps {
  centered?: boolean;
  children: React.ReactNode;
  fullwidth?: boolean;
}

const getNavBar = (isMobileDevice: boolean | null) => {
  if (isMobileDevice === null) return null;
  return isMobileDevice ? <DynamicMobileNavBar /> : <DynamicDesktopNavBar />;
};

export default function PageLayout({centered = false, children, fullwidth = false}: PageLayoutProps) {
  const router = useRouter();
  const {loading} = useNavigationContext();
  const isMobileDevice = useIsTargetMedia('desktop', 'less', true);

  const className = classNames(s.list, {
    [s.main]: !fullwidth,
    [s.centered]: centered && !fullwidth,
  });

  return (
    <div className={s.container}>
      {getNavBar(isMobileDevice)}
      {loading ? (
        <PageSpinner />
      ) : (
        <main className={className} key={`main-container-${router.asPath}`}>
          {children}
        </main>
      )}
      <footer className={s.footer}></footer>
    </div>
  );
}
