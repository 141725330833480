import React from 'react';

import {Analytics as VercelAnalytics} from '@vercel/analytics/react';

import AccountContextProvider from '@/contexts/AccountContext';
import AppConfigContextProvider from '@/contexts/AppConfigContext';
import {CashBalanceAuthProvider} from '@/contexts/CashBalanceAuthContext';
import {CashHistoryAuthProvider} from '@/contexts/CashHistoryAuthContext';
import ComplianceContextProvider from '@/contexts/ComplianceContext';
import ExchangeRatesProvider from '@/contexts/ExchangeRatesContext';
import TransactionsContextProvider from '@/contexts/TransactionsContext';
import UserContextProvider from '@/contexts/UserContext';

import LocationControl from '@/components/LocationControl/LocationControl';
import PrivateRouteSafetyControl from '@/components/PrivateRouteSafetyControl';
import {TrackingControl} from '@/components/TrackingControl/TrackingControl';

import type {SessionStatesProps} from '@/pages/_app';

import UserInfo from './UserInfo/UserInfo';

export type PageInitialProps = SessionStatesProps;

export default function PageInitiator({
  setUserDidEnterSoftPin,
  userDidEnterSoftPin,
  isNativeWrapper,
  children,
  ...fbUserProps
}: PageInitialProps & {
  children: React.ReactNode;
}) {
  return (
    <>
      <UserContextProvider
        {...fbUserProps}
        isNativeWrapper={isNativeWrapper}
        userDidEnterSoftPin={userDidEnterSoftPin}
        setUserDidEnterSoftPin={setUserDidEnterSoftPin}>
        <ExchangeRatesProvider>
          <AppConfigContextProvider>
            <ComplianceContextProvider>
              <AccountContextProvider>
                <CashBalanceAuthProvider>
                  <CashHistoryAuthProvider>
                    <TransactionsContextProvider>
                      <TrackingControl />
                      <LocationControl>
                        <PrivateRouteSafetyControl>
                          <>
                            <UserInfo />
                            {children}
                          </>
                        </PrivateRouteSafetyControl>
                      </LocationControl>
                    </TransactionsContextProvider>
                  </CashHistoryAuthProvider>
                </CashBalanceAuthProvider>
              </AccountContextProvider>
            </ComplianceContextProvider>
          </AppConfigContextProvider>
        </ExchangeRatesProvider>
      </UserContextProvider>
      <VercelAnalytics />
    </>
  );
}
