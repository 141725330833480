import {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';

import {afterGet, lastKeyAfterGet} from '@/controllers/transactions';
import {getTransactions} from '@/graphql/queries';
import {NetworkStatus, useQuery} from '@apollo/client';

import {getFormattedDate, isTodayOrYesterday} from '@/utils/time';
import {transformTransactionsList} from '@/utils/transactions/format';

import type {
  GetTransactionsQuery,
  GetTransactionsQueryVariables,
  LastKey,
} from '@/graphql/__generated__/graphql';
import type {TransactionFilters, TransactionsArray} from '@/types/transactions';
import type {ApolloError} from '@apollo/client';
import type {ReactNode} from 'react';

import {useAccount} from './AccountContext';
import {useComplianceContext} from './ComplianceContext';
import {useUser} from './UserContext';

type TransactionContextType = {
  transactions: TransactionsArray;
  lastTXKeyInfo: LastKey | null | undefined;
  groupedByDateTxs: {[key: string]: TransactionsArray};
  groupedByDateCurrencyTxs: {[key: string]: TransactionsArray};
  loadingTransactions: boolean;
  isEmptyTxList: boolean;
  txsFetchError: ApolloError | null;
  refetchTransactions: Function;
  fetchMoreTransactions: Function;
  setTransactionFilters: (filters: TransactionFilters | null) => void;
  isEndOfList: boolean;
};

const TransactionContext = createContext<TransactionContextType>({
  transactions: [],
  lastTXKeyInfo: undefined,
  groupedByDateTxs: {},
  groupedByDateCurrencyTxs: {},
  loadingTransactions: false,
  isEmptyTxList: false,
  txsFetchError: null,
  refetchTransactions: () => null,
  fetchMoreTransactions: () => null,
  setTransactionFilters: () => null,
  isEndOfList: false,
});
export const useTransactions = () => useContext(TransactionContext);

const TransactionsContextProvider = ({children}: {children: ReactNode}) => {
  const {user} = useUser();
  const {account} = useAccount();
  const {canSkipKyc} = useComplianceContext();
  const skip = !user || !canSkipKyc;

  const {
    data,
    error,
    networkStatus,
    refetch: refetchTransactions,
    fetchMore: fetchMoreTransactions,
  } = useQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(getTransactions, {
    notifyOnNetworkStatusChange: true,
    variables: {input: {limit: 15, useImprovedFees: true}},
    skip: skip,
    nextFetchPolicy: 'cache-first',
  });

  const [transactionFilters, setTransactionFilters] = useState<TransactionFilters | null>(null);
  const transactions = useMemo(() => afterGet(data), [data]);

  // All transactions
  const formattedTxs = useMemo(() => transformTransactionsList(transactions), [transactions]);
  const groupedByDateTxs = useMemo(() => {
    if (!formattedTxs) return {};
    return getTransactionsGroupedByDate(formattedTxs);
  }, [formattedTxs]);
  const lastTXKeyInfo = useMemo(() => (data ? lastKeyAfterGet(data) : undefined), [data]);

  const formattedCurrencyTxs = useMemo(() => {
    const transactions = afterGet(data);
    return transformTransactionsList(transactions);
  }, [data]);

  const groupedByDateCurrencyTxs = useMemo(() => {
    if (!formattedCurrencyTxs) return {};
    return getTransactionsGroupedByDate(formattedCurrencyTxs);
  }, [formattedCurrencyTxs]);

  const isEndOfList = useMemo(() => {
    if (lastTXKeyInfo === undefined) return false;
    return lastTXKeyInfo === null;
  }, [lastTXKeyInfo]);

  const networkCondition = [NetworkStatus.loading, NetworkStatus.refetch, NetworkStatus.setVariables];

  const loadingTransactions =
    !skip &&
    (networkCondition.includes(networkStatus) ||
      (lastTXKeyInfo === undefined && networkStatus !== NetworkStatus.ready));

  const handleRefetch = useCallback(() => {
    // This condition prevents an immediate call to getTransactions when the PWA first loads
    if (!user?.id || skip) {
      return;
    }

    refetchTransactions({
      input: {useImprovedFees: true, limit: 15, filters: transactionFilters || {}},
    });
  }, [refetchTransactions, transactionFilters, user?.id, skip]);

  const handleFetchMore = useCallback(() => {
    if (networkStatus === NetworkStatus.fetchMore || isEndOfList) {
      return;
    }
    if (skip) {
      return;
    }
    fetchMoreTransactions({
      variables: {
        input: {
          filters: transactionFilters || {},
          limit: 15,
          useImprovedFees: true,
          lastKey: {
            PK: lastTXKeyInfo?.PK,
            SK: lastTXKeyInfo?.SK,
            GSI1PK: lastTXKeyInfo?.GSI1PK,
          },
        },
      },
    });
  }, [transactionFilters, isEndOfList, networkStatus, skip]);

  useEffect(() => {
    if (skip) {
      return;
    }
    // Whenever the balance subscription updates the balance, we should refetch the transactions
    handleRefetch();
  }, [account, transactionFilters, handleRefetch, skip]);

  return (
    <TransactionContext.Provider
      value={{
        transactions: formattedTxs,
        lastTXKeyInfo,
        groupedByDateTxs,
        groupedByDateCurrencyTxs,
        loadingTransactions,
        isEmptyTxList: skip || (transactions.length === 0 && lastTXKeyInfo !== undefined),
        txsFetchError: error || null,
        refetchTransactions,
        fetchMoreTransactions: handleFetchMore,
        setTransactionFilters,
        isEndOfList,
      }}>
      {children}
    </TransactionContext.Provider>
  );
};

export default TransactionsContextProvider;

const getTransactionsGroupedByDate = (transactions: TransactionsArray) =>
  transactions.reduce(
    (acc, tx) => {
      const ISOCreatedAt = tx?.createdAt;
      const dateToGroupBy = getFormattedDate(ISOCreatedAt, 'FULLSTRINGDATE_SLASH');
      const todayOrYesterdayString = isTodayOrYesterday(ISOCreatedAt);

      const finalKey = todayOrYesterdayString ? `${todayOrYesterdayString} ${dateToGroupBy}` : dateToGroupBy;
      if (!acc[finalKey]) {
        acc[finalKey] = [];
      }
      acc[finalKey].push(tx);
      return acc;
    },
    {} as {[key: string]: TransactionsArray},
  );
