import {createContext, useCallback, useContext, useEffect, useState} from 'react';

import {exchangeRatesUpdatedSubscription} from '@/graphql/subscriptions';

import {afterGet} from '@/controllers/exchangeRates';
import {getImprovedExchangeRates} from '@/graphql/queries';

import {useToastNotificationContext} from '@/contexts/ToastNotificationContext';
import {useSubscribeToEntity} from '@/hooks/useSubscribeToEntity';

import type {
  ExchangeRates,
  GetImprovedExchangeRatesQuery,
  PublishedExchangeRatesSubscription,
} from '@/graphql/__generated__/graphql';
import type {EmptyObject} from '@/types/generics';
import type {ReactNode} from 'react';

import {useUser} from './UserContext';

type ExchangeRatesType = {
  exchangeRates: ExchangeRates | EmptyObject;
  exchangeRatesLoading: boolean;
  exchangeRatesError: string;
  exchangeRatesTimestamp: number;
  refetchExchangeRates: Function;
  isEmptyExchangeRates: boolean;
};

const ExchangeRates = createContext<ExchangeRatesType>({
  exchangeRates: {},
  exchangeRatesLoading: false,
  exchangeRatesError: '',
  exchangeRatesTimestamp: 0,
  refetchExchangeRates: () => null,
  isEmptyExchangeRates: true,
});

export const useExchangeRates = () => useContext(ExchangeRates);

const ExchangeRatesProvider = ({children}: {children: ReactNode}) => {
  const {user} = useUser();
  const {setToastNotification} = useToastNotificationContext();
  const [latestExchangeRates, setLatestExchangeRates] = useState<ExchangeRates | EmptyObject>({});
  const [exchangeRatesTimestamp, setExchangeRatesTimestamp] = useState<number>(new Date().getTime());

  const {
    entity: exchangeRates,
    // TODO: Use when subscriptions stable
    // loading,
    queryLoading,
    error,
    refetchQuery: refetchExchangeRates,
  } = useSubscribeToEntity<
    NonNullable<ExchangeRates>,
    GetImprovedExchangeRatesQuery,
    PublishedExchangeRatesSubscription
  >({
    query: getImprovedExchangeRates,
    subscription: exchangeRatesUpdatedSubscription,
    waitsFor: !user,
    controllerTransform: afterGet,
  });

  useEffect(() => {
    if (!exchangeRates || Object.keys(exchangeRates).length === 0) return;

    const formattedExchangeRates = exchangeRates.rates?.reduce((acc: Record<string, number>, rate) => {
      if (rate && rate.pair) {
        acc[String(rate.pair)] = rate.value ?? 0;
      }
  
      return acc;
    }, {} as Record<string, number>);

    setLatestExchangeRates(formattedExchangeRates || {});
    setExchangeRatesTimestamp(new Date().getTime());
  }, [exchangeRates, setToastNotification]);

  const handleRefetchExchangeRates = useCallback(async () => {
    try {
      await refetchExchangeRates();
      setToastNotification({messageConfig: {summary: 'Exchange rates have been refreshed!'}});
    } catch (error) {
      //...
      console.log('Exchange rates update failed', error);
    }
  }, [refetchExchangeRates, setToastNotification]);

  return (
    <ExchangeRates.Provider
      value={{
        exchangeRates: latestExchangeRates,
        exchangeRatesLoading: queryLoading,
        exchangeRatesError: error,
        exchangeRatesTimestamp,
        refetchExchangeRates: handleRefetchExchangeRates,
        isEmptyExchangeRates: Object.keys(latestExchangeRates).length === 0,
      }}>
      {children}
    </ExchangeRates.Provider>
  );
};

export default ExchangeRatesProvider;
