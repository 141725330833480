import {NextRequest} from 'next/server';

import {ValidationError} from '@/errors/ValidationError';
import {DeviceType, InitiateSignupInput, PlatformType} from '@/graphql/__generated__/graphql';
import {ServiceApolloErrors, ServiceValidationErrors} from '@/types/serviceErrors';
import dayjs from 'dayjs';
import * as yup from 'yup';

import {
  initiateSignup,
  resetPin,
  setPersonalDetails,
  setUserPin,
  startRecoveryPinFlow,
  updateUser,
  validateRecoveryPin,
} from '@/graphql/mutations';
import {ApolloClient, ApolloError} from '@apollo/client';

import {ERROR_LEVEL} from '@/constants';

import {referralService} from '../referral/ReferralService';
import {kaLog} from '../tracking/kastaLogs/KaLog';
import TrackingService from '../tracking/TrackingService';

interface UserProfile {
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
}

export interface GeoLocation {
  city?: string;
  country?: string;
  region?: string;
  latitude?: string;
  longitude?: string;
}

export class UserService {
  static APOLLO_ERROR: ServiceApolloErrors = {
    USER_NOT_FOUND: {
      CODE: 'user/kasta/user-not-found',
      MATCH: /user .* not found/,
      MSG: "Whoops! We couldn't find your user. Please contact support.",
      LVL: ERROR_LEVEL.CRITICAL,
    },
    USER_STATUS_ERROR: {
      CODE: 'user/kasta/user-status-error',
      MATCH: /user .* has already set a pin/,
      MSG: "Whoops! It seems like you've already set a pin.",
      LVL: ERROR_LEVEL.NOTICE,
    },
    EMPTY_PIN: {
      CODE: 'user/kasta/empty-pin',
      MATCH: /pin is empty/,
      MSG: 'It seems like your pin is empty. Try again.',
      LVL: ERROR_LEVEL.NOTICE,
    },
    VERIFICATION_NOT_EXPIRED_COOLDOWN: {
      MATCH: /VERIFICATION_NOT_EXPIRED_COOLDOWN/,
      CODE: 'user/kasta/verification-not-expired-cooldown',
      MSG: 'You will need to wait 1 minute before you can retry.',
      LVL: ERROR_LEVEL.NOTICE,
    },
    VERIFICATION_NOT_EXPIRED_LOCKOUT: {
      MATCH: /VERIFICATION_NOT_EXPIRED_LOCKOUT/,
      CODE: 'user/kasta/verification-not-expired-lockout',
      MSG: 'You will need to wait 10 minute before you can retry.',
      LVL: ERROR_LEVEL.NOTICE,
    },
    VERIFICATION_CONSUMED_ATTEMPTS: {
      MATCH: /VERIFICATION_CONSUMED_ATTEMPTS/,
      CODE: 'user/kasta/verification-attempts-consumed',
      MSG: 'You will need to wait 10 minute before you can retry.',
      LVL: ERROR_LEVEL.NOTICE,
    },
    VERIFICATION_EXPIRED_TIMEOUT: {
      MATCH: /VERIFICATION_EXPIRED_TIMEOUT/,
      CODE: 'user/kasta/verification-expired-timeout',
      MSG: 'Recovery code expired. Please start the flow again.',
      LVL: ERROR_LEVEL.NOTICE,
    },
    RECOVERY_CODE_ALREADY_VALIDATED: {
      MATCH: /RECOVERY_PIN_ALREADY_VALIDATED/,
      CODE: 'user/kasta/recovery-pin-already-validated',
      MSG: 'Recovery code already validated. Please start the flow again.',
      LVL: ERROR_LEVEL.NOTICE,
    },
  } as const;

  static VALIDATION_ERROR: ServiceValidationErrors = {
    PINCODE_MISMATCH: {
      MSG: 'PIN codes do not match.',
      CODE: 'user/kasta/pin-code-mismatch',
    },
    PHONE_NUMBER_NOT_FOUND: {
      MSG: 'Phone number not found.',
      CODE: 'user/kasta/phone-number-not-found',
    },
    INVALID_RECOVERY_CODE: {
      MSG: 'Invalid recovery code',
      CODE: 'user/kasta/invalid-recovery-code',
    },
  } as const;

  static GENERIC_ERROR = {
    INITIATE_SIGNUP: 'Could not initiate signup. Please try again or contact support',
    UPDATE_PROFILE: 'Could not set personal details during sign up. Please try again or contact support.',
    UPDATE_PHONE_NUMBER: 'Could not update phone number. Please try again or contact support.',
    SET_PIN_CODE: 'Could not set pin code. Please try again or contact support.',
    RECOVER_PIN: 'Failed to recover PIN. Please try again or contact customer support.',
    VALIDATE_RECOVERY_CODE:
      'Failed to validate your recovery code. Please try again or contact customer support.',
    SET_NEW_PIN: 'Failed to set new pin. Please try again or contact customer support.',
  } as const;

  static USER_PROFILE_SCHEMA = yup
    .object({
      firstName: yup
        .string()
        .min(1, 'Must contain at least 1 character')
        .trim('Must not begin or end with a space')
        .required('First name required'),
      lastName: yup
        .string()
        .min(1, 'Must contain at least 1 character')
        .trim('Must not begin or end with a space')
        .required('Last name required'),
      dateOfBirth: yup
        .date()
        .max(dayjs().subtract(18, 'years').toDate(), 'You must be at least 18 years old.')
        .required('Date of birth required'),
    })
    .required();

  static RECOVERY_CODE_SCHEMA = yup
    .object({
      recoveryCode: yup
        .string()
        .matches(/^\d+$/, 'Must contain only numbers')
        .min(6, 'Must be at least 6 characters')
        .max(6, 'Must be at most 6 characters')
        .required('Recovery code required'),
    })
    .required();

  static ENTER_PIN_SCHEMA = yup
    .object({
      userPin: yup
        .string()
        .matches(/^\d+$/, 'Must contain only numbers')
        .min(6, 'Must be at least 6 characters')
        .max(6, 'Must be at most 6 characters')
        .required('Pin required'),
    })
    .required();

  static COUNTRY_SCHEMA = yup
    .object({
      country: yup.string().required('Country required'),
    })
    .required();

  private client: ApolloClient<any>;
  private tracking: TrackingService;

  constructor(tracking: TrackingService, client: ApolloClient<any>) {
    this.client = client;
    this.tracking = tracking;
  }

  async initiateSignup({
    email,
    countryCode,
    newUserByGoogleAuth,
    marketingOptIn,
  }: {
    email: string;
    countryCode: string;
    newUserByGoogleAuth?: boolean;
    marketingOptIn?: boolean;
  }) {
    const input: InitiateSignupInput = {
      email,
      address: {countryCode},
      deviceType: DeviceType.Web,
      deviceAppInstanceId: kaLog.getGACookieClientId(),
      newUserByGoogleAuth,
      referrerCode: referralService.getReferrer() || undefined,
      marketingOptIn,
    };

    /*
     * Temporary workaround for AppSync validation issue.
     * The API doesn't accept an empty string for deviceAppInstanceId.
     * A default value is set if the getGACookieClientId() returns an empty string.
     */
    if (!input.deviceAppInstanceId) {
      input.deviceAppInstanceId = 'undefined';
    }

    try {
      const {data} = await this.client.mutate({
        mutation: initiateSignup,
        variables: {
          input,
        },
      });

      return data?.initiateSignup || null;
    } catch (error: unknown) {
      this.tracking.logServiceError({error, apolloErrors: UserService.APOLLO_ERROR});
      throw error;
    }
  }

  public async updateProfile(profile: UserProfile) {
    try {
      await this.client.mutate({
        mutation: setPersonalDetails,
        variables: {
          input: {
            ...profile,
            dateOfBirth: profile.dateOfBirth?.toISOString(),
            tracking: {
              latestPlatformUsed: PlatformType.Pwa,
            },
          },
        },
      });
    } catch (error: unknown) {
      this.tracking.logServiceError({error, apolloErrors: UserService.APOLLO_ERROR});
      throw error;
    }
  }

  public async updatePhoneNumber(phoneNumber: string) {
    try {
      await this.client.mutate({
        mutation: updateUser,
        variables: {
          input: {
            phoneNumber,
          },
        },
      });
    } catch (error: unknown) {
      this.tracking.logServiceError({error, apolloErrors: UserService.APOLLO_ERROR});
      throw error;
    }
  }

  public async sendRecoveryPin() {
    try {
      await this.client.mutate({
        mutation: startRecoveryPinFlow,
      });
    } catch (error: unknown) {
      this.tracking.logServiceError({error, apolloErrors: UserService.APOLLO_ERROR});
      throw error;
    }
  }

  // Created only so we could encapsulate the error throwing
  public validatePinCodes(pin1: string, pin2?: string) {
    if (pin1 !== pin2) {
      throw new ValidationError({
        message: UserService.VALIDATION_ERROR.PINCODE_MISMATCH.MSG,
        code: UserService.VALIDATION_ERROR.PINCODE_MISMATCH.CODE,
      });
    }
  }

  public validatePhoneNumber(phoneNumber?: string | null) {
    if (!phoneNumber) {
      throw new ValidationError({
        message: UserService.VALIDATION_ERROR.PHONE_NUMBER_NOT_FOUND.MSG,
        code: UserService.VALIDATION_ERROR.PHONE_NUMBER_NOT_FOUND.CODE,
      });
    }
  }

  public async setPinCode(pin: string) {
    try {
      await this.client.mutate({
        mutation: setUserPin,
        variables: {
          input: {pin},
        },
      });
    } catch (error: unknown) {
      this.tracking.logServiceError({error, apolloErrors: UserService.APOLLO_ERROR});
      throw error;
    }
  }

  public async getUserLocationAndIP(): Promise<
    {geo: GeoLocation | undefined; ip: string | undefined} | undefined
  > {
    const isEmpty = (obj: Record<any, any>) => {
      return JSON.stringify(obj) === '{}';
    };

    try {
      const response = await fetch('/api/location');
      const data = await response.json();
      const location = data.geo as NextRequest['geo'];
      const ip = data.ip;

      return {
        geo: !!location && !isEmpty(location) ? location : undefined,
        ip: ip ?? undefined,
      };
    } catch (error) {
      // This is not an error that needs to be handled or shown to users,
      // so we can log it directly like this and then move on.
      this.tracking.logError({
        error_message: (error as any).message || JSON.stringify(error),
        error_level: ERROR_LEVEL.NOTICE,
        error_message_id: 'auth/kasta/could-not-detect-user-location-or-ip',
      });
    }
  }

  public async resetPinCode(newPin: string, recoveryCode: string) {
    try {
      await this.client.mutate({
        mutation: resetPin,
        variables: {
          input: {
            newPin,
            recoveryPin: recoveryCode,
          },
        },
      });
    } catch (error: unknown) {
      this.tracking.logServiceError({error, apolloErrors: UserService.APOLLO_ERROR});
      throw error;
    }
  }

  public async validateRecoveryCode(pin: string) {
    try {
      const response = await this.client.mutate({
        mutation: validateRecoveryPin,
        variables: {
          input: {
            pin,
          },
        },
      });

      const {data} = response;
      if (!data?.validateRecoveryPin?.valid) {
        const attemptsLeftNum = data?.validateRecoveryPin?.attemptsLeft || 0;
        const attemptsLeftMsg = `${attemptsLeftNum} attempt${attemptsLeftNum > 1 ? 's' : ''} left.`;

        throw new ValidationError({
          message: UserService.VALIDATION_ERROR.INVALID_RECOVERY_CODE.MSG + '; ' + attemptsLeftMsg,
          code: UserService.VALIDATION_ERROR.INVALID_RECOVERY_CODE.CODE,
        });
      }

      return response;
    } catch (error: unknown) {
      this.tracking.logServiceError({error, apolloErrors: UserService.APOLLO_ERROR});
      throw error;
    }
  }

  public handleUserError({
    error,
    setError,
    fallbackErrorMsg,
    shouldThrow,
    onPinCodeMismatch,
    onInvalidRecoveryCode,
  }: HandleUserErrorProps) {
    console.error(error);
    const throwIfRequested = () => {
      if (shouldThrow) throw error;
    };

    const fallback = () => {
      if (setError && fallbackErrorMsg) {
        setError(fallbackErrorMsg);
      }
    };

    // Validation errors are usually a special case.
    // The callinng component decides if it wants to set an error or throw an error from these validation functions.
    if (error instanceof ValidationError) {
      if (error.message.includes(UserService.VALIDATION_ERROR.PINCODE_MISMATCH.MSG)) {
        return onPinCodeMismatch ? onPinCodeMismatch(error) : fallback();
      }

      if (error.message.includes(UserService.VALIDATION_ERROR.INVALID_RECOVERY_CODE.MSG)) {
        return onInvalidRecoveryCode ? onInvalidRecoveryCode(error) : fallback();
      }

      // This one doesn't need special handling
      if (error.message.includes(UserService.VALIDATION_ERROR.PHONE_NUMBER_NOT_FOUND.MSG)) {
        setError?.(UserService.VALIDATION_ERROR.PHONE_NUMBER_NOT_FOUND.MSG);
        return throwIfRequested();
      }
    }

    if (!setError) {
      return throwIfRequested();
    }

    if (error instanceof ApolloError) {
      if (UserService.APOLLO_ERROR.USER_NOT_FOUND.MATCH.test(error.message)) {
        setError?.(UserService.APOLLO_ERROR.USER_NOT_FOUND.MSG);
        return throwIfRequested();
      }

      if (UserService.APOLLO_ERROR.EMPTY_PIN.MATCH.test(error.message)) {
        setError?.(UserService.APOLLO_ERROR.EMPTY_PIN.MSG);
        return throwIfRequested();
      }

      if (UserService.APOLLO_ERROR.USER_STATUS_ERROR.MATCH.test(error.message)) {
        setError?.(UserService.APOLLO_ERROR.USER_STATUS_ERROR.MSG);
        return throwIfRequested();
      }

      // @ts-ignore
      const errorType = error.graphQLErrors[0]?.errorType as string;

      if (UserService.APOLLO_ERROR.VERIFICATION_NOT_EXPIRED_COOLDOWN.MATCH.test(errorType)) {
        setError?.(UserService.APOLLO_ERROR.VERIFICATION_NOT_EXPIRED_COOLDOWN.MSG);
        return throwIfRequested();
      }

      if (UserService.APOLLO_ERROR.VERIFICATION_NOT_EXPIRED_LOCKOUT.MATCH.test(errorType)) {
        setError?.(UserService.APOLLO_ERROR.VERIFICATION_NOT_EXPIRED_LOCKOUT.MSG);
        return throwIfRequested();
      }

      if (UserService.APOLLO_ERROR.VERIFICATION_CONSUMED_ATTEMPTS.MATCH.test(errorType)) {
        setError?.(UserService.APOLLO_ERROR.VERIFICATION_CONSUMED_ATTEMPTS.MSG);
        return throwIfRequested();
      }

      if (UserService.APOLLO_ERROR.VERIFICATION_EXPIRED_TIMEOUT.MATCH.test(errorType)) {
        setError?.(UserService.APOLLO_ERROR.VERIFICATION_EXPIRED_TIMEOUT.MSG);
        return throwIfRequested();
      }

      if (UserService.APOLLO_ERROR.RECOVERY_CODE_ALREADY_VALIDATED.MATCH.test(errorType)) {
        setError?.(UserService.APOLLO_ERROR.RECOVERY_CODE_ALREADY_VALIDATED.MSG);
        return throwIfRequested();
      }
    }

    if (!fallbackErrorMsg) {
      return throwIfRequested();
    }

    setError(fallbackErrorMsg);
    return throwIfRequested();
  }
}

interface HandleUserErrorProps {
  error: unknown;
  setError?: (message: string) => void;
  fallbackErrorMsg?: string;
  shouldThrow?: boolean;
  onPinCodeMismatch?: (error: ValidationError) => void;
  onInvalidRecoveryCode?: (error: ValidationError) => void;
}
