import {useCallback, useEffect} from 'react';
import {useRouter} from 'next/router';

import {Currency, GetUserQuery, PlatformType} from '@/graphql/__generated__/graphql';
import {userUpdatedSubscription} from '@/graphql/subscriptions';
import packageJson from '@/package.json';
import dayjs from 'dayjs';
import {updateProfile} from 'firebase/auth';
import {createContext, useContext, useContextSelector} from 'use-context-selector';

import {afterGet} from '@/controllers/user';
import {updateUser} from '@/graphql/mutations';
import {getUser} from '@/graphql/queries';
import {useMutation, useQuery, useSubscription} from '@apollo/client';

import {ERROR_LEVEL} from '@/constants';
import useEntityServerClientMerge from '@/hooks/useEntityServerClientMerge';
import useIsPwaInstalled from '@/hooks/useIsPwaInstalled';
import {useZendeskLogin} from '@/hooks/useZendeskLogin';
import {mixPanel} from '@/services/tracking/analytics/MixPanel';
import {tracking} from '@/services/tracking/TrackingService';

import type {SessionStatesProps} from '@/pages/_app';
import type {User} from '@/types/user';
import type {User as FbUser} from 'firebase/auth';
import type {Dispatch, ReactNode, SetStateAction} from 'react';

type UserContextType = {
  user: User | null;
  fetchAndSetUser: () => Promise<User | null>;
  displayCurrency: Currency;
  userLoading: boolean;
  userError: string;
  fbUser: FbUser | null;
  setFBUserOnce: boolean;
  userDidEnterSoftPin: boolean;
  setUserDidEnterSoftPin?: Dispatch<SetStateAction<boolean>>;
  rawUserQuery?: GetUserQuery | null;
  skipUserFetching: boolean;
  userJustSignInOrSignUp: () => boolean;
  isNativeWrapper?: boolean;
  updateUserFields: (fields: Partial<User>) => Promise<void>;
};

const UserContext = createContext<UserContextType>({
  user: null,
  fetchAndSetUser: async () => null,
  displayCurrency: Currency.Usd,
  userLoading: false,
  userError: '',
  fbUser: null,
  setFBUserOnce: false,
  userDidEnterSoftPin: false,
  rawUserQuery: null,
  skipUserFetching: true,
  userJustSignInOrSignUp: () => false,
  isNativeWrapper: false,
  updateUserFields: async () => {},
});

const signInTimeThreshold = 10;

export const useUser = () => useContext(UserContext);
export const useIsNativeWrapper = () => useContextSelector(UserContext, c => c.isNativeWrapper);

const UserContextProvider = ({
  children,
  fbUser,
  setFBUserOnce,
  userDidEnterSoftPin,
  setUserDidEnterSoftPin,
  isNativeWrapper,
}: SessionStatesProps & {
  children: ReactNode;
}) => {
  const isPwaInstalled = useIsPwaInstalled();
  const {pathname, query} = useRouter();
  const {justSignedUp, justSignedIn} = query;

  // In signup/login pages, user will be fetched manually when we're certain that the USER# record exists in our database
  const skipUserFetching =
    ['/login', '/signup'].some(subpath => pathname.includes(subpath)) || !fbUser || fbUser.isAnonymous;

  const {
    data: userQueryData,
    refetch: refetchUser,
    error: queryError,
    loading: queryLoading,
  } = useQuery(getUser, {skip: skipUserFetching});

  const userJustSignInOrSignUp = useCallback(() => {
    if (justSignedUp || justSignedIn) {
      return true;
    }

    if (!fbUser?.metadata) {
      return false;
    }

    const lastSignInTime = dayjs(fbUser?.metadata?.lastSignInTime);
    const diff = dayjs().diff(lastSignInTime, 'second');

    return diff < signInTimeThreshold;
  }, [fbUser, justSignedUp, justSignedIn]);

  useEffect(() => {
    // @ts-expect-error - graphQLErrors errorType not in ApolloDefinition
    const graphQlerrorType = queryError?.graphQLErrors?.[0]?.errorType ?? '';
    // @ts-expect-error - networkError statusCode not in ApolloDefinition
    const networkErrorType = queryError?.networkError?.statusCode;
    if (graphQlerrorType === 'Unauthorized' || networkErrorType === 401) {
      refetchUser();
    }
  }, [queryError, refetchUser]);

  const {
    data: subData,
    error: subError,
    loading: subLoading,
  } = useSubscription(userUpdatedSubscription, {skip: skipUserFetching});

  const [user, fetchAndSetUser] = useEntityServerClientMerge<User | null>({
    hydratatedData: userQueryData,
    subData,
    afterGet,
    lazyQuery: refetchUser,
  });

  const [updateUserMutation] = useMutation(updateUser);
  useEffect(() => {
    async function updatePlatform() {
      const userPlatformUpdated = sessionStorage.getItem('userPlatformUpdated');
      if (user && !userPlatformUpdated) {
        try {
          await updateUserMutation({variables: {input: {latestPlatformUsed: PlatformType.Pwa}}});
          sessionStorage.setItem('userPlatformUpdated', 'true');
        } catch (error: any) {
          // failing because async data between fbUser id and user id storaged
          // it will work when user updates
          tracking.logError({
            error_message: error.message || JSON.stringify(error),
            error_level: ERROR_LEVEL.NOTICE,
            error_message_id: 'user/kasta/unable-to-update-last-platform-used',
          });
        }
      }
    }
    updatePlatform().then();
  }, [user, updateUserMutation]);

  const updateUserFields = useCallback(
    async (fields: Partial<User>) => {
      try {
        await updateUserMutation({variables: {input: fields}});
        refetchUser();
      } catch (error: any) {
        tracking.logError({
          error_message: error.message || JSON.stringify(error),
          error_level: ERROR_LEVEL.NOTICE,
          error_message_id: 'user/kasta/unable-to-update-user-fields',
        });
      }
    },
    [updateUserMutation, refetchUser],
  );

  // TODO: update when this is implemented at user settings
  const displayCurrency = Currency.Usd;

  useZendeskLogin({
    kastaUser: user,
    fbUser,
  });

  useEffect(() => {
    if (!user || !fbUser || fbUser.isAnonymous) {
      return;
    }

    if (!fbUser.displayName) {
      updateProfile(fbUser, {
        displayName: `${user.firstName} ${user.lastName}`,
      });
    }

    if (user) {
      // About Identify https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#identify
      mixPanel?.identify(user.id);

      // We set the user profile properties here, which will always contain the latest value.
      mixPanel?.set({
        $name: `${user.firstName} ${user.lastName}`,
        $email: user?.email,
        cashAccountStatus: user?.cashAccountStatus,
        countryCode: user?.address?.countryCode,
        kycLevel: user?.kyc?.level,
        kycStatus: user?.kyc?.status,
        userStatus: user?.userStatus,
        'PWA Installed': isPwaInstalled,
        'PWA Version': packageJson.version,
      });

      // https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#super-properties
      // We set some super properties here, which will be sent with each event.
      // This is interesting because now we'll have the values for these properties
      // at the time of the event, and not just the latest value like in the user profile.
      mixPanel?.register({
        kyc_level: user?.kyc?.level,
        kyc_status: user?.kyc?.status,
        user_status: user?.userStatus,
        cash_account_status: user?.cashAccountStatus,
        pwa_installed: isPwaInstalled,
        pwa_version: packageJson.version,
        is_native_wrapper: isNativeWrapper,
      });
    }
  });

  return (
    <UserContext.Provider
      value={{
        user,
        fetchAndSetUser,
        displayCurrency,
        userLoading: queryLoading,
        userError: subError?.message || '',
        fbUser,
        setFBUserOnce,
        userDidEnterSoftPin,
        setUserDidEnterSoftPin,
        rawUserQuery: userQueryData,
        skipUserFetching,
        userJustSignInOrSignUp,
        isNativeWrapper,
        updateUserFields,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
