import {Currency, EntryType, TransactionStatus, TransactionType} from '@/graphql/__generated__/graphql';
import {TX_EVENT_TYPE_PARAMS} from '@/types/tracking';

import type {ERROR_EVENT_INFO, TIER_EVENT_TYPE_PARAMS} from '@/types/tracking';

export type EventsKey = keyof typeof events;

export const events = {
  //====== Signup ======
  sign_up_initiated: () => ({name: 'sign_up_initiated', idempotencyId: 'sign_up_initiated'}),

  // Step 1 - Choose country
  select_country_of_residence_signup: (countryName: string) => ({
    name: 'select_country_of_residence_signup',
    params: {country: countryName},
  }),
  submit_country_of_residence_signup: () => ({name: 'submit_country_of_residence_signup'}),

  //----------------------------

  // Step 2 - Enter email
  start_filling_email_signup: () => ({name: 'start_filling_email_signup'}),
  start_filling_email_signin: () => ({name: 'start_filling_email_signin'}),
  submit_email_signup: (email: string) => ({
    name: 'submit_email_signup',
    params: {
      email,
    },
  }),
  send_email_code_signup: () => ({name: 'send_email_code_signup'}),

  // Step 3 - Email sent
  resend_email_code_signup: () => ({name: 'resend_email_code_signup'}),

  // Step 4 - Set PIN code
  email_code_success_signup: () => ({name: 'email_code_success_signup'}),
  // email_success_signin_signup: () => ({name: 'email_success_signin_signup'}), // ❌ duplicate email_code_success_signup at pincode page load
  submit_first_pin_code_signup: () => ({name: 'submit_first_pin_code_signup'}),
  first_pin_code_success_signup: () => ({name: 'first_pin_code_success_signup'}),
  submit_second_pin_code_signup: () => ({name: 'submit_second_pin_code_signup'}),
  second_pin_code_success_signup: () => ({name: 'second_pin_code_success_signup'}),
  second_pin_code_fail_signup: () => ({name: 'second_pin_code_fail_signup'}),
  start_filling_first_pin_code_signup: () => ({
    name: 'start_filling_first_pin_code_signup',
    idempotencyId: 'start_filling_first_pin_code_signup',
  }),
  start_filling_second_pin_code_signup: () => ({
    name: 'start_filling_second_pin_code_signup',
    idempotencyId: 'start_filling_second_pin_code_signup',
  }),

  // Step 5 - enter user info
  start_filling_first_name_signup: () => ({name: 'start_filling_first_name_signup'}),
  start_filling_last_name_signup: () => ({name: 'start_filling_last_name_signup'}),
  select_date_of_birth_signup: (dateIsoString: string) => ({
    name: 'select_date_of_birth_signup',
    params: {date_of_birth: dateIsoString},
  }),
  submit_personal_info_signup: () => ({name: 'submit_personal_info_signup'}),
  signup_success: (utmParams: {[key: string]: string}) => ({
    name: 'signup_success',
    params: {
      eventInfo: {
        eventName: 'signup_success',
      },
      marketing: {
        ...utmParams,
      },
    },
  }),

  /****** KYC ***********/
  on_kyc_initialized: () => ({
    name: 'on_kyc_initialized',
  }),
  on_kyc_applicant_submitted: () => ({
    name: 'on_kyc_applicant_submitted',
  }),
  /***** Sign in *****/
  signin_success: () => ({name: 'signin_success'}),
  start_filling_pin_code: () => ({name: 'start_filling_pin_code'}),
  submit_pin_code: () => ({name: 'submit_pin_code'}),
  submit_pin_code_success: () => ({name: 'submit_pin_code_success'}),
  submit_pin_code_failure: () => ({name: 'submit_pin_code_failure'}),

  /**********  Logout **********/

  log_out: (userId?: string) => ({
    name: 'log_out',
    params: {userId},
  }),
  inactivity_lock_app: () => ({
    name: 'inactivity_lock_app',
  }),

  //====== General ======
  error: (err: ERROR_EVENT_INFO) => ({
    name: 'error_message',
    params: err,
  }),

  open_chat_support: () => ({name: 'open_chat_support'}),

  start_filling_pin_code_tx: () => ({name: 'start_filling_pin_code_tx'}),
  submit_pin_code_tx: () => ({name: 'submit_pin_code_tx'}),
  pin_code_success_tx: () => ({name: 'pin_code_success_tx'}),
  pin_code_fail_tx: () => ({name: 'pin_code_fail_tx'}),
  limit_order_interest: () => ({name: 'limit_order_interest'}),

  //====== Tiers ======
  upgrade_initiated_tier_number: (params: TIER_EVENT_TYPE_PARAMS) => {
    if (typeof params === 'object' && 'num' in params) {
      return {
        name: `upgrade_initiated_tier_${params.num}`,
        params: {
          currencies_selected: params.currenciesSelected,
          currency_type: params.currencyType,
          total_amount_fiat: params.totalAmountFiat,
          total_amount_crypto: params.totalAmountCrypto,
        },
      };
    }
    return {name: 'upgrade_initiated_tier'};
  },
  upgrade_success_tier_number: (params: TIER_EVENT_TYPE_PARAMS) => {
    if (typeof params === 'object' && 'num' in params) {
      return {
        name: `upgrade_success_tier_${params.num}`,
        params: {
          currencies_selected: params.currenciesSelected,
          currency_type: params.currencyType,
          total_amount_fiat: params.totalAmountFiat,
          total_amount_crypto: params.totalAmountCrypto,
        },
      };
    }
    return {name: 'upgrade_success_tier_number'};
  },
  tiers_compare_table_tab: () => ({name: 'tiers_compare_table_tab'}),
  tiers_faqs_tab: () => ({name: 'tiers_faqs_tab'}),
  copy_beneficiary: () => ({
    name: 'copy_beneficiary',
  }),
  copy_iban: () => ({
    name: 'copy_iban',
  }),
  copy_bank_code: () => ({
    name: 'copy_bank_code',
  }),
  cash_accept_terms_and_conditions: () => ({
    name: 'cash_accept_terms_and_conditions',
  }),
  tx_withdraw: (params: TX_EVENT_TYPE_PARAMS) => {
    const suffix = params.sourceCurrency === Currency.Eur ? 'cash' : 'crypto';
    return {
      name: `tx_withdraw_${suffix}`,
      params: {
        entryType: EntryType.Debit,
        status: TransactionStatus.Created,
        type: TransactionType.Withdraw,
        source_currency: params.sourceCurrency,
        source_amount: params.sourceAmount,
        amount_includes_fees: params.amountIncludesFees,
      },
    };
  },
  tx_convert: (params: TX_EVENT_TYPE_PARAMS) => {
    let suffix = 'crypto';
    if (params.sourceCurrency === Currency.Eur) {
      suffix = 'from_cash';
    }
    if (params.destCurrency === Currency.Eur) {
      suffix = 'to_cash';
    }
    return {
      name: `tx_convert_${suffix}`,
      params: {
        entryType: EntryType.Debit,
        status: TransactionStatus.Created,
        type: TransactionType.Swap,
        source_currency: params.sourceCurrency,
        source_amount: params.sourceAmount,
        dest_currency: params.destCurrency,
      },
    };
  },
  tx_send: (params: TX_EVENT_TYPE_PARAMS) => {
    const suffix = params.sourceCurrency === Currency.Eur ? 'cash' : 'crypto';
    return {
      name: `tx_send_${suffix}`,
      params: {
        entryType: EntryType.Debit,
        status: TransactionStatus.Created,
        type: TransactionType.Transfer,
        source_amount: params.sourceAmount,
        source_currency: params.sourceCurrency,
      },
    };
  },

  /********* Cash **********/
  on_cash_kyc_applicant_submitted: () => ({
    name: 'on_cash_kyc_applicant_submitted',
  }),

  /********* Debit Card **********/
  debit_card_accept_terms_and_conditions: () => ({
    name: 'debit_card_accept_terms_and_conditions',
  }),
  debit_card_initiate_order: () => ({
    name: 'debit_card_initiate_order',
  }),
  debit_card_confirm_order: () => ({
    name: 'debit_card_confirm_order',
  }),

  //====== Card Top Up ======
  card_top_up_fetch_consent_token: () => ({
    name: 'card_top_up_fetch_consent_token',
    params: {
      description: "Fetch user consent token in order to charge the user's payment method.",
    },
  }),
  card_top_up_waiting_for_consent_token: () => ({
    name: 'card_top_up_waiting_for_consent_token',
    params: {
      description: "Waiting for user consent token in order to charge the user's payment method.",
    },
  }),
  card_top_up_consent_token_received: () => ({
    name: 'card_top_up_consent_token_received',
    params: {
      description: 'User consent token has either been received or a timeout occurred.',
    },
  }),
  card_top_up_charge_new_payment_method: (params: {amount: string; shouldSaveCard: string}) => ({
    name: 'card_top_up_charge_new_payment_method',
    params: {
      ...params,
      description:
        'User has triggered a top-up using a new payment method. We will charge this payment method now.',
    },
  }),
  card_top_up_charge_saved_payment_method: (amount: string) => ({
    name: 'card_top_up_charge_payment_method',
    params: {
      amount,
      description:
        'User has triggered a top-up using a previously saved payment method. We will charge this payment method now.',
    },
  }),
  card_top_up_toggle_default_payment_method: (shouldSaveCard: string) => ({
    name: 'card_top_up_toggle_default_payment_method',
    params: {
      shouldSaveCard,
      description: `Has user chosen to save this payment method as default? ${shouldSaveCard}`,
    },
  }),
  card_top_up_confirmation_started: () => ({
    name: 'card_top_up_confirmation_started',
    params: {
      description: 'Top-up confirmation started. Checking limits and searching for pending transactions...',
    },
  }),
  card_top_up_charge_payment_method_success: () => ({
    name: 'card_top_up_charge_payment_method_success',
    params: {
      description: 'Top-up payment confirmed. Persisting transaction and redirecting...',
    },
  }),
  card_top_up_try_again: (amount: string) => ({
    name: 'card_top_up_try_again',
    params: {
      amount,
      description: `User has chosen to try the top-up again with amount ${amount}.`,
    },
  }),
  card_top_up_handle_convert: (params: {destAmount: string; destCurrency: string}) => ({
    name: 'card_top_up_handle_convert',
    params: {
      ...params,
      description: `User has chosen to convert ${params.destAmount} to ${params.destCurrency}.`,
    },
  }),

  // Buy Crypto - top up + swap
  buy_crypto_fetch_consent_token: () => ({
    name: 'buy_crypto_fetch_consent_token',
    params: {
      description: "Fetch user consent token in order to charge the user's payment method.",
    },
  }),
  buy_crypto_waiting_for_consent_token: () => ({
    name: 'buy_crypto_waiting_for_consent_token',
    params: {
      description: "Waiting for user consent token in order to charge the user's payment method.",
    },
  }),
  buy_crypto_consent_token_received: () => ({
    name: 'buy_crypto_consent_token_received',
    params: {
      description: 'User consent token has either been received or a timeout occurred.',
    },
  }),
  buy_crypto_new_payment_method: (params: {
    fiatAmount: string | undefined;
    cryptoAmount: string | undefined;
    cryptoCurrency: string | undefined;
  }) => ({
    name: 'buy_crypto_charge_new_payment_method',
    params: {
      ...params,
      description:
        'User will start crypto buying using a new payment method. We will receive the card info and charge this payment method.',
    },
  }),
  buy_crypto_confirm_new_payment_method: (params: {
    fiatAmount: string | undefined;
    cryptoAmount: string;
    shouldSaveCard: string;
  }) => ({
    name: 'buy_crypto_charge_new_payment_method',
    params: {
      ...params,
      description:
        'User confirmed crypto buying using a new payment method. We will charge this payment method now.',
    },
  }),
  buy_crypto_saved_payment_method: (params: {
    fiatAmount: string | undefined;
    cryptoAmount: string | undefined;
    cryptoCurrency: string | undefined;
  }) => ({
    name: 'buy_crypto_saved_payment_method',
    params: {
      ...params,
      description:
        'User will start a crypto buying using a previously saved payment method. We will confirm the card info and charge this payment method.',
    },
  }),
  buy_crypto_confirm_saved_payment_method: (params: {
    fiatAmount: string | undefined;
    cryptoAmount: string | undefined;
    cryptoCurrency: string | undefined;
  }) => ({
    name: 'buy_crypto_saved_payment_method',
    params: {
      ...params,
      description:
        'User will start a crypto buying using a previously saved payment method. We will confirm the card info and charge this payment method.',
    },
  }),
  buy_crypto_confirmation_started: () => ({
    name: 'buy_crypto_confirmation_started',
    params: {
      description:
        'Crypto buying confirmation started. Checking limits and searching for pending transactions...',
    },
  }),
  buy_crypto_charge_payment_method_success: () => ({
    name: 'card_top_up_charge_payment_method_success',
    params: {
      description: 'Top-up payment confirmed. Persisting transaction and redirecting...',
    },
  }),
  buy_crypto_subscription_started: () => ({
    name: 'buy_crypto_subscription_started',
    params: {
      description: 'Subscription for swap on buy crypto flow started. Waiting for response...',
    },
  }),
  buy_crypto_subscription_redirect: () => ({
    name: 'buy_crypto_subscription_redirect',
    params: {
      description:
        'Subscription for swap on buy crypto returned a redirectUrl. We will open it for user confirm.',
    },
  }),
  buy_crypto_try_again: (amount: string) => ({
    name: 'buy_crypto_try_again',
    params: {
      amount,
      description: `User has chosen to try the buy crypto again with amount ${amount}.`,
    },
  }),

  //====== 2FA ======
  click_activate_2fa_settings_item: () => ({name: 'click_activate_2fa_settings_item'}),
  click_unbind_2fa_settings_item: () => ({name: 'click_unbind_2fa_settings_item'}),
};
