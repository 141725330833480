import {useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import {usePrevious} from 'primereact/hooks';

import {useApolloClient} from '@apollo/client';

import {useUser} from '@/contexts/UserContext';
import {kaLog} from '@/services/tracking/kastaLogs/KaLog';
import {tracking} from '@/services/tracking/TrackingService';

export function TrackingControl() {
  const {fbUser, user} = useUser();
  const {asPath, pathname, query} = useRouter();
  const client = useApolloClient();

  const [loggedEnterPathname, setLoggedEnterPathname] = useState(false);

  useEffect(() => {
    kaLog.setApolloClient(client);
  }, [client]);

  useEffect(() => {
    kaLog.setFirebaseUserId(fbUser);
    if (user) {
      kaLog.setKastaUser(user);
    }
    tracking.setUser(fbUser, user);
  }, [fbUser, user]);

  useEffect(() => {
    kaLog.setScreenName(asPath);
  }, [asPath]);

  const prevAsPath = usePrevious(asPath);
  const prevPathname = usePrevious(pathname);
  useEffect(() => {
    if (!fbUser) return;

    if (!loggedEnterPathname) {
      tracking.logScreenView();
      setLoggedEnterPathname(true);
      return;
    }

    if (pathname.includes('login') && asPath !== prevAsPath) {
      tracking.logScreenView();
      return;
    }

    if (pathname !== prevPathname) {
      tracking.logScreenView();
      tracking.logEventBasedOnPath({pathname, prevPathname, query});
    }
  }, [fbUser, loggedEnterPathname, asPath, prevAsPath, pathname, prevPathname, query]);
  return null;
}
